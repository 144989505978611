import React, { useState } from "react"
import ContactForm from "./ContactForm"

function TestContactForm() {
  const [validated, setValidated] = useState(false)

  const handleSubmit = event => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }
    // Uncoment for test purpose
    // event.preventDefault()
    setValidated(true)
    console.log("form sent")
  }

  return (
    <>
      <ContactForm />
      {/* <form
      name="contact2"
      method="POST"
      data-netlify="true"
      action="/contactsuccess"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="fomr-name" value="contact2" />
      <p>
        <label>
          Your Name: <input type="text" name="name" />
        </label>
      </p>
      <p>
        <label>
          Your Email: <input type="email" name="email" />
        </label>
      </p>
      <p>
        <label>
          Your Role:{" "}
          <select name="role[]" multiple>
            <option value="leader">Leader</option>
            <option value="follower">Follower</option>
          </select>
        </label>
      </p>
      <p>
        <label>
          Message: <textarea name="message"></textarea>
        </label>
      </p>
      <p>
        <button type="submit">Send</button>
      </p>
    </form> */}
    </>
  )
}

export default TestContactForm
