import React from "react"
import ContactForm from "../components/contactForm/ContactForm"
import TestContactForm from "../components/contactForm/TestContactForm"
import { Helmet } from "react-helmet"

function formTest() {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      // <ContactForm />
      <TestContactForm />
    </>
  )
}

export default formTest
